<template>
    <div>
      <base-switch :value="internalToggle" @input="handleSwitchChange"></base-switch>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      max_value: {
        type: Number,
        required: true
      },
      value: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        internalToggle: this.value === this.max_value
      };
    },
    watch: {
      value(newVal) {
        this.internalToggle = newVal === this.max_value;
      },
      internalToggle(newToggleValue) {
        this.$emit('input', newToggleValue ? this.max_value : 0);
      }
    },
    methods: {
      handleSwitchChange(newValue) {
        this.internalToggle = newValue;
      }
    }
  }
  </script>
  