<template>
    <div>
        <b-card no-body>
            <b-card-body>
                <div v-if="$apollo.loading">
                    <b-row> 
                        <b-col>
                            <h1>Loading Submission</h1>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col
                            class="d-flex align-items-center justify-content-center"
                        >
                            <h1>
                                <i class="fas fa-spinner fa-spin ml-1"></i>
                            </h1>
                        </b-col>
                    </b-row>
                </div>
                <div v-else>
                    <b-row>
                        <b-col v-if="submission && submission.team"
                            ><h1>{{ submission.team.name }}</h1></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col>
                            <SubmissionRender
                                v-if="
                                    submission &&
                                    submission.template &&
                                    submission.response
                                "
                                :form_template="submission.template.schema"
                                :response_data="submission.response"
                            />
                        </b-col>
                    </b-row>
                </div>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
// Modules

import { processTeamRelayData, Submission } from "@/ORM/Hackathon/Team.js";
import {
    processHackathonRelayData,
    Hackathon,
} from "@/ORM/Hackathon/Hackathon.js";

// Queries
import { GET_HACKATHON_SUBMISSION } from "@/graphql/queries";

// Mutations

import SubmissionRender from "@/views/Components/FormTemplate/Render.vue";

export default {
    name: "SubmissionJudgeView",
    components: {
        SubmissionRender,
    },
    props: {
        hackathon_id: {
            type: String,
            description: "The Id of the hackathon being viewed.",
            default: null,
        },
        submission_id: {
            type: String,
            description: "The Id of the submission being viewed.",
            default: null,
        },
    },
    apollo: {
        get_hackathon_submission: {
            query: GET_HACKATHON_SUBMISSION,
            result(res) {
                this.submission = processTeamRelayData(res);

                // this.hackathon =
                //     processHackathonRelayData(res) || new Hackathon();
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                    submission_id: this.submission_id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_submission = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_submission: null,
            },
            submission: new Submission(),
        };
    },
    methods: {
        manage_get_hackathon_submission() {
            if (this.hackathon_id && this.submission_id) {
                graph_utils.enable_query(
                    this.$apollo.queries.get_hackathon_submission
                );
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_submission
                );
            }
        },
    },
    watch: {
        hackathon_id() {
            this.manage_get_hackathon_submission();
        },
    },
    mounted() {
        if (!this.$apollo.queries.get_hackathon_submission.skip) {
            this.$apollo.queries.get_hackathon_submission.refetch();
        }
    },
    created() {
        // setting this queries fetch policy
        graph_utils.set_fetch_cache_and_network(
            this.$apollo.queries.get_hackathon_submission
        );
    },
};
</script>

<style></style>
