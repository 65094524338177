import { Submission} from "@/ORM/Hackathon/Team.js";

// judgingModels.js



class Criteria {
    constructor(data = {}) {
        data = data || {};
        this.id = data.id || "";
        this.hackathon = data.hackathon || null;
        this.name = data.name || "";
        this.description = data.description || null;
        this.max_score = data.max_score || 0;
        this.type = data.type || Criteria.TypeOpts.SCORE;
        this.category = data.category || null;
        this.goals = data.goals || [];
    }

    static TypeOpts = {
        BINARY: "binary",
        SCORE: "score"
    };
}

class Judge {
    constructor(data = {}) {
        data = data || {};
        this.id = data.id || "";
        this.profile = data.profile || null;
        this.hackathon = data.hackathon || null;
        this.status = data.status || Judge.StatusOpts.ACTIVE;
    }

    static StatusOpts = {
        ACTIVE: "active",
        DISABLED: "disabled"
    };
}

class JudgeInvite {
    constructor(data = {}) {
        data = data || {};
        this.id = data.id || "";
        this.uid = data.uid || null;
        this.hackathon = data.hackathon || null;
        this.email = data.email || "";
        this.status = data.status || JudgeInvite.StatusOpts.PENDING;
        this.responded_profile = data.responded_profile || null;
    }

    static StatusOpts = {
        PENDING: "pending",
        ACCEPTED: "accepted",
        DECLINED: "declined"
    };
}

class Score {
    constructor(data = {}) {
        data = data || {};
        this.id = data.id || "";
        this.judge = data.judge || null;
        this.submission = data.submission ? new Submission(data.submission) : null;
        this.criteria = data.criteria || null;
        this.score = data.score || 0;
        this.completed = data.completed || false;
    }
}

class JudgingStatus {
    constructor(data = {}) {
        data = data || {};
        this.id = data.id || "";
        this.hackathon = data.hackathon || null;
        this.status = data.status || JudgingStatus.StatusOpts.CLOSED;
    }

    static StatusOpts = {
        READY: "ready",
        CLOSED: "closed",
        JUDGE_COMPLETE: "judge_complete"
    };
}



const modelMapping = {
    hackathonCriteria: Criteria,
    allHackathonCriteria: Criteria,
    hackathonJudge: Judge,
    allHackathonJudges: Judge,
    hackathonJudgeInvite: JudgeInvite,
    allHackathonJudgeInvites: JudgeInvite,
    hackathonJudgingScore: Score,
    allHackathonJudgingScore: Score,
    hackathonJudgingStatus: JudgingStatus,
    allHackathonJudgingStatuses: JudgingStatus
};

function processJudgingRelayData(inputObject) {
    for (let key in inputObject.data) {
        if (modelMapping[key]) {
            const ModelClass = modelMapping[key];
            
            // Handle array of instances (for "all" prefixed keys)
            if (key.startsWith("allHackathon") && inputObject.data[key].edges) {
                return inputObject.data[key].edges.map(
                    (edge) => new ModelClass(edge.node)
                );
            }

            // Handle single instance
            return new ModelClass(inputObject.data[key]);
        }
    }
    return null;
}

export { 
    Criteria, 
    Judge, 
    JudgeInvite, 
    Score, 
    JudgingStatus, 
    processJudgingRelayData 
};
